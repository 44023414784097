import axios from 'axios';

// Base URL of the backend
const API_BASE_URL = process.env.REACT_APP_BASE_URL; // Update this with your API URL

// Function to set the token in the headers for authenticated requests
export const setAuthToken = (token) => {
  if (token) {
   
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Function to log in the user
export const login = async (email, password) => {
  const response = await axios.post(`${API_BASE_URL}/auth/login`, { email, password });
  return response.data; // Should contain the JWT token
};

// Fetch the e-transfers for the client
export const getEtransfers = async ({ page, limit, from, to }) => {
    
  const response = await axios.get(`${API_BASE_URL}/etransfers`, {
    params: { page, limit, from, to },
  });
  return response.data;
};

// Fetch e-transfer requests
export const getEtransferRequests = async ({ page, limit, from, to }) => {
    const response = await axios.get(`${API_BASE_URL}/etransfers/requests`, {
      params: { page, limit, from, to },
    });
    return response.data;
  };